import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2997050974/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "hasprofileattributevalue"
    }}>{`hasProfileAttributeValue`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`hasProfileAttributeValue`}</inlineCode>{` function is similar to its `}<inlineCode parentName="p">{`getProfileAttributeValue`}</inlineCode>{` siblings with
the exception that instead of returning a value it is used to validate if the attribute contains the value.`}</p>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p>{`The functions take the following arguments:`}</p>
    <pre><code parentName="pre" {...{}}>{`hasProfileAttributeValue(context, attrName, value, ignoreCase, matchAll) -> boolean
* context -> object = the context of the attribute, e.g. kapp or space.
* attrName -> string = The name of the attribute.
* value -> string | string[] | (string) => boolean = The value or values to search for or predicate function.
* ignoreCase -> boolean = Flag whether comparison is case sensitive (default) or not.
* matchAll -> boolean = When multiple values are being matched force whether all values must be in the attribute or not.
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Utils } from '@kineticdata/bundle-common';

// Verify that the User has the attribute 'Colors' and that blue and green
// are both present, case does not matter.
Utils.hasProfileAttributeValue(user, 'Colors', ['Blue', 'green'], true, true);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      